import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  container: {
    height: "100vh",
    width: "100vw",
    overflow: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: 0,
    left: 0,
  },

  logotypeContainer: {
    backgroundColor: theme.palette.primary.main,
    width: "60%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  mobileLogo: {
    display: "none",
    [theme.breakpoints.down("md")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  },
  logotypeImage: {
    width: 250,
    // marginBottom: theme.spacing(4),
    [theme.breakpoints.down("md")]: {
      width: 80,
      marginTop: theme.spacing(3),
    },
  },
  formContainer: {
    width: "40%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#435f60",
    padding: theme.spacing(4),
    overflowX: "hidden",
    overflowY: "auto",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      padding: theme.spacing(4),
      backgroundColor: "#435f60",
    },
  },
  formContainerSelectRoutine: {
    width: "40%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#fafbfd",
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down("md")]: {
      width: "100%",
      backgroundColor: "#fafbfd",
    },
    overflowX: "hidden",
    overflowY: "auto",
    "& .slick-slider": {
      marginTop: 20,
      marginBottom: 20,
      width: "100%",
      "& .slick-list": {
        "& .slick-track": {
          width: "50000px!important",
        },
        overflow: "visible !important",
        "& .slick-slide": {
          minWidth: "300px !important",
        },
        "& .slick-slide > div": {
          width: "100%",
          display: "flex",
          justifyContent: "center",
        },
      },
      "& .slick-dots > li": {
        margin: 0,
        "& button": {
          padding: 0,
        },
      },
    },
  },
  formContainerComplete: {
    width: "40%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#435f60",
    overflowX: "hidden",
    overflowY: "auto",
    padding: theme.spacing(6),
    [theme.breakpoints.down("md")]: {
      width: "100%",
      padding: theme.spacing(6),
      backgroundColor: "#435f60",
    },
  },
  form: {
    maxWidth: 300,
  },
  appForm: {
    maxWidth: 210,
  },
  creatingButtonContainer: {
    marginTop: 8,
    height: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  createAccountButton: {
    height: 40,
    textTransform: "none",
    borderRadius: "32px",
    backgroundColor: "#3D5B73",
    color: "white",
    "&:hover": {
      backgroundColor: "#0481a3",
      color: "white",
    },
    "&.Mui-disabled": {
      backgroundColor: "#fff",
      color: "#347090",
    },
  },
  errorMessage: {
    textAlign: "center",
    fontSize: "14px",
  },
  completeMessageContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  completeMessage: {
    textAlign: "center",
    fontSize: 20,
    color: "#002C39",
    marginBottom: 15,
  },
  completeGuide: { textAlign: "center", fontSize: 20, fontWeight: "normal", color: "#002C39", marginTop: 15 },
  gotoButtonContainer: {
    marginTop: 60,
    marginBottom: 20,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  appStoreBtn: { width: 200, height: "auto", marginBottom: 10 },
  googlePlayBtn: { width: 200, height: "auto", marginTop: 10 },
  inputRoot: {
    border: 0,
    backgroundColor: "transparent",

    "&.Mui-focused": {
      backgroundColor: "transparent",
    },

    "&:hover": {
      backgroundColor: "unset",
    },
    "& > .MuiFilledInput-input": {
      border: 0,
      backgroundColor: "#fff",
      padding: "12px",
      borderRadius: "12px",
      color: "#00293a",
    },
  },
  selectAnotherRoutine: {
    textAlign: "center",
    color: "#002C39",
    marginTop: 10,
    fontSize: 12,
    fontWeight: 600,
  },
  copyright: {
    marginTop: theme.spacing(3),
    textAlign: "center",
    color: "#cfdbdf",
    fontSize: 14,
    [theme.breakpoints.up("md")]: {
      bottom: theme.spacing(2),
    },

    "& > a": {
      color: "#cfdbdf",
    },
  },
  selectedUserAvatar: {
    width: 100,
    height: 100,
    textTransform: "uppercase",
    fontSize: 30,
  },
  routineProfileContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 30,
  },
  routineProfileName: {
    color: "#3D5B73",
    marginTop: 10,
    marginBottom: 10,
    fontSize: 18,
    letterSpacing: 0.5,
  },
  headerTitleContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginBottom: 30,
  },
  headerTitle: {
    textAlign: "center",
    color: "#002C39",
    fontSize: 24,
    fontWeight: "bold",
    lineHeight: 1.2,
  },
  selectRoutineHeader: {
    display: "flex",
    flexDirection: "column",
    width: 330,
  },
  selectRoutineHeaderTitle: {
    marginTop: 10,
    marginBottom: 10,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    textAlign: "center",
    color: "#002C39",
    fontWeight: 600,
  },
  selectRoutineButton: {
    borderRadius: 40,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 5,
    paddingBottom: 5,
    textTransform: "none",
    width: "auto",
    backgroundColor: "#3D5B73",
    color: "white",
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: "#0481a3",
    },
  },
  credentialDiv: {
    backgroundColor: "#009383",
    paddingTop: 3,
    paddingBottom: 3,
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 20,
  },
  credentialName: {
    fontSize: 10,
    color: "white",
    letterSpacing: 0.5,
    textAlign: "center",
  },
  credentialContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: 5,
    justifyContent: "center",
  },
  carouselItemContainer: {
    // width: "85% !important",
    width: 250,
    backgroundColor: "#435f60",
    position: "relative",
    borderRadius: 20,
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      // width: "70% !important",
    },
  },
  carouselItemAvatar: {
    position: "absolute",
    width: 55,
    height: 55,
    left: -15,
    top: -15,
    fontSize: 28,
  },
  routineStatement: {
    fontSize: 12,
    textAlign: "center",
    color: "white",
    letterSpacing: 0.5,
    marginTop: 20,
    marginBottom: 20,
    paddingLeft: 10,
    paddingRight: 10,
  },
  routineMixTitle: {
    fontSize: 14,
    marginBottom: 20,
    color: "#3D5B73",
  },
  loadingItem: {
    marginTop: 30,
  },
}));
