import React, { useEffect } from "react";
import { useLocation, useParams, useHistory } from "react-router-dom";

export const RoutineRedirect = () => {
  const params = useParams();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (params.id) {
      history.push(`/create?ref=${params.id}`);
    }
  }, [params, location, history]);
  return <></>;
};
