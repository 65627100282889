import { Typography } from "@material-ui/core";
import React, { useMemo } from "react";
import { getColorPalette } from "../../service/utils";
import useStyles from "./styles";
import logo from "../../assets/img/logo-icon.png";

const colors = {
  avatarBackground: ["#004E65", "#F2F2F2", "#435f60", "#5E6C84", "#009383"],
  avatarInitial: ["#EFEFEF", "#3F5B7C", "#EFEFEF", "#EFEFEF", "#EFEFEF"],
};

export const StyleAvatar = ({ uid, src, string, fontSize }) => {
  const classes = useStyles();
  const colorPalette = useMemo(() => getColorPalette(uid, colors?.avatarBackground?.length || 1), [uid]);

  return (
    <div
      className={classes.avatarContainer}
      style={{ backgroundColor: colors?.avatarBackground ? colors?.avatarBackground[colorPalette] : "#3F5B73" }}
    >
      {!src && (
        <div className={classes.logoContainer}>
          <img src={logo} alt="" className={classes.logo} />
        </div>
      )}
      <Typography
        component="h3"
        className={classes.name}
        style={{ color: colors?.avatarInitial ? colors?.avatarInitial[colorPalette] : "#FFFFFF", fontSize: fontSize }}
      >
        {string}
      </Typography>
      <div className={classes.avatar} style={{ backgroundImage: `url(${src})` }}></div>
    </div>
  );
};
