import React from "react";
import _ from "lodash";
// import useFirebase from "../hooks/useFirebase";
import moment from "moment";

var UserStateContext = React.createContext();
var UserDispatchContext = React.createContext();

function userReducer(state, action) {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return { ...state, isAuthenticated: true };
    case "LOGIN_FAILURE":
      return { ...state, isAuthenticated: false };
    case "SIGN_OUT_SUCCESS":
      return { ...state, isAuthenticated: false };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UserProvider({ children }) {
  // const firebase = useFirebase();

  var [state, dispatch] = React.useReducer(userReducer, {
    isAuthenticated: false,
  });

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>{children}</UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  var context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }
  return context;
}

function useUserDispatch() {
  var context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserProvider");
  }
  return context;
}

export { UserProvider, useUserState, useUserDispatch, loginUser, registerUser, signOut };

// ###########################################################
async function registerUser(
  firebase,
  firestore,
  dispatch,
  firstname,
  lastname,
  email,
  password,
  partner,
  legacy,
  referUserID,
  history,
  setIsLoading,
  setError,
  selectedRoutineID,
) {
  setError({});
  setIsLoading(true);

  let _uid = null;
  try {
    const {
      user: { uid },
    } = await firebase.auth().createUserWithEmailAndPassword(email, password);
    _uid = uid;

    await firestore
      .collection("users")
      .doc(uid)
      .set({
        email,
        firstname,
        lastname,
        partner,
        emailReminder: true,
        emailStatus: true,
        notificationReminder: true,
        notificationStatus: true,
        activitySummaryEmail: true,
        createdOn: moment().format("YYYY/MM/DD"),
        shareActivity: true,
        promptQuestionnaire: true,
        feedLoadedDateTime: moment().subtract(1, "days").toDate().getTime(),
        selectedRoutine: selectedRoutineID,
        ...(legacy
          ? {
              trialEndsDate: moment().add(7, "days").format("YYYY/MM/DD"),
              monthlyRenewalDay: Math.min(28, moment().add(7, "days").date()),
              billingStatus: 1,
              subscriptionEnds: "",
              subscriptionType: "legacy",
            }
          : {
              billingStatus: 0,
              subscriptionType: "iap",
            }),
        referringUser: referUserID,
        showedTip: false,
        timeZone: "America/Denver",
        notificationAsk: true,
        trackingAsk: true,
        welcomeToolTip: true,
        routineShownToday: moment().format("YYYY/MM/DD"),
        homeToolTip: true,
      });

    setError({});
    setIsLoading(false);
    history.push({
      pathname: "/apps",
      state: { signed: true },
    });
    return true;
  } catch (e) {
    console.log("regeister failed", e.message);
    setIsLoading(false);
    if (e.message === "The email address is already in use by another account.") {
      setError({
        email:
          "That email address is already in use. You may log in to that account or create a new account with a different email address.",
      });
    } else {
      setError({ all: "Something went wrong! Please try again after 2~3 mins" });
      if (_uid) {
        setTimeout(() => {
          firebase.functions().httpsCallable("deleteAuth")({ uid: _uid });
        }, 120000);
      }
    }
  }
  return false;
}

async function loginUser(firebase, dispatch, email, password, history, setIsLoading, setError) {
  setError({});
  setIsLoading(true);
  try {
    const result = await firebase.auth().signInWithEmailAndPassword(email, password);

    const userId = _.get(result, "user.uid");

    const journals = await firebase.firestore().collection("journals").where("userId", "==", userId).get();

    dispatch({ type: "LOGIN_SUCCESS" });
    setIsLoading(false);
    if (!journals.empty) {
      history.push("/my-journal");
    } else {
      history.push("/set-journal");
    }
    return true;
  } catch (e) {
    if (e.code === "auth/user-not-found") {
      setError({ email: "There is no user for this email." });
    } else if (e.code === "auth/wrong-password") {
      setError({ password: "Enter your correct password" });
    } else {
      setError({
        all: "The email address and password you provided don't match an account. Please try again or reset your password.",
      });
    }
    setIsLoading(false);
  }
  return false;
}

async function signOut(firebase, dispatch, history) {
  firebase.auth().signOut();
  dispatch({ type: "SIGN_OUT_SUCCESS" });
  history.push("/");
}
