import { Typography } from "@material-ui/core";
import React from "react";
import useStyles from "./styles";

export const Credential = ({ credential }) => {
  const classes = useStyles();

  return (
    <div className={classes.credentialDiv}>
      <Typography className={classes.credentialName}>{credential}</Typography>
    </div>
  );
};
