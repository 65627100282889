import _ from "lodash";
import actionTypes from "../actions/actionType";

const initialState = {
  contract: {},
  bill: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CONTRACT.SET:
      return _.set(state, ["contract"], action.payload);

    case actionTypes.BILL.SET:
      return _.set(state, ["bill"], action.payload);

    default:
      return state;
  }
};
