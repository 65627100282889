import React, { useState } from "react";
import RoutineUserContext from "../context/RoutineUserContext";

const RoutineUserProvider = ({ children }) => {
  const [currentRoutine, setCurrentRoutine] = useState({});
  const [currentRoutineUser, setCurrentRoutineUser] = useState({});
  const [createdUserName, setCreatedUserName] = useState("");
  return (
    <RoutineUserContext.Provider
      value={{ currentRoutine, setCurrentRoutine, currentRoutineUser, setCurrentRoutineUser, createdUserName, setCreatedUserName }}
    >
      {children}
    </RoutineUserContext.Provider>
  );
};

export default RoutineUserProvider;
