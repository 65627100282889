import { Grid, Link, Typography } from "@material-ui/core";
import React, { useContext } from "react";
import { Credential } from "./signup/credential";
import useStyles from "./signup/styles";
import logo from "../assets/img/logo-icon.png";
import googlePlayImg from "../assets/img/google-play.png";
import appleStoreImg from "../assets/img/app-store.png";
import { getNameAvatar } from "../service/utils";
import RoutineUserContext from "../context/RoutineUserContext";
import { StyleAvatar } from "../components/Avatar";
import { APPSTORE_URL, PLAYSTORE_URL } from "../constants/consts";

export const AppDownLoadPage = () => {
  const { currentRoutine, currentRoutineUser, createdUserName } = useContext(RoutineUserContext);
  const classes = useStyles();

  return (
    <Grid container className={classes.container}>
      <div className={classes.logotypeContainer}>
        <img src={logo} alt="logo" className={classes.logotypeImage} />
      </div>
      <div className={classes.formContainerComplete}>
        <div className={classes.appForm}>
          <div className={classes.routineProfileContainer}>
            <div className={classes.selectedUserAvatar}>
              <StyleAvatar
                fontSize="30px"
                uid={currentRoutine.userId}
                src={currentRoutineUser.profilePhoto}
                string={getNameAvatar(currentRoutineUser.firstname, currentRoutineUser.lastname)}
              />
            </div>
            <Typography component="h3" className={classes.routineProfileName}>
              {currentRoutineUser.firstname} {currentRoutineUser.lastname}
            </Typography>
            {!!currentRoutine?.credentials?.length && <Credential credential={currentRoutine.credentials[0]} />}
          </div>
          <div className={classes.completeMessageContainer}>
            <Typography className={classes.completeMessage}>
              I've just made two
              <br />
              recommendations for
              <br />
              you
              {!!createdUserName && ", "}
              {!!createdUserName && <b>{createdUserName}</b>}.
            </Typography>
            <Typography className={classes.completeGuide}>
              Get the app, then log in
              <br />
              to see them.
            </Typography>
          </div>
          <div className={classes.gotoButtonContainer}>
            <Link href={APPSTORE_URL}>
              <img src={appleStoreImg} className={classes.appStoreBtn} alt="" />
            </Link>
            <Link href={PLAYSTORE_URL}>
              <img src={googlePlayImg} className={classes.googlePlayBtn} alt="" />
            </Link>
          </div>
          <div className={classes.mobileLogo}>
            <img src={logo} alt="logo" className={classes.logotypeImage} />
          </div>
        </div>
      </div>
    </Grid>
  );
};
