import { createStore, combineReducers } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import contractReducer from "./redux/reducers/contract";

export default (initialState = {}) => {
  const rootReducer = combineReducers({
    contract: contractReducer,
  });

  return createStore(rootReducer, initialState, composeWithDevTools());
};
