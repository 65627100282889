import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  avatarContainer: {
    width: "100%",
    height: "100%",
    textTransform: "uppercase",
    overflow: "hidden",
    borderRadius: "50%",
    position: "relative",
  },
  name: {
    width: "100%",
    height: "100%",
    border: "3px solid #3D5B73",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  avatar: {
    width: "100%",
    height: "100%",
    position: "absolute",
    backgroundSize: "contain",
    border: "3px solid #3D5B73",
    borderRadius: "50%",
    left: 0,
    top: 0,
  },
  logoContainer: {
    width: "100%",
    height: "100%",
    position: "absolute",
    left: 0,
    top: 0,
    padding: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& img": {
      width: "100%",
    },
  },
}));
