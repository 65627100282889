import React, { useEffect, useState } from "react";
import {
  makeStyles,
  Grid,
  Fade,
  LinearProgress,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import bgImg from "../assets/img/dory_bg_img.jpg";

import useFirestore from "../hooks/useFirestore";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100vh",
    width: "100vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: 0,
    left: 0,
  },
  formContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position:"relative",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      padding: theme.spacing(3),
    },
  },
  formBg: {
    position: "absolute",
    width: "100%",
    height: "100%",
    left: 0,
    top: 0,
    backgroundPosition: "center",
    backgroundSize: "cover",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: -1,
    color: "white",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      padding: theme.spacing(3),
    },
  },
  errorMessage: {
    textAlign: "center",
  },
}));

function ActivityEmailOffPage() {
  const classes = useStyles();
  const history = useHistory();
  const firestore = useFirestore();


  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const urlParams = new URLSearchParams(history.location.search);
    const userId = urlParams.get('id')

    const fn = async () => {
      setLoading(true);

      await firestore
      .collection("users")
      .doc(userId)
      .update({
        activitySummaryEmail: false,
      });
      setLoading(false);
    };

    fn();
  }, [firestore, history.location.search]);

  return (
    <Grid container className={classes.container}>
      <div className={classes.formContainer}>
        <div
          className={classes.formBg}
          style={{ backgroundImage: `url(${bgImg})` }}
        >
          <Fade in={isLoading}>
            <LinearProgress variant="query" />
          </Fade>
          {!isLoading &&
          <h1>Activity summary emails are now turned off.</h1>
        }
        </div>
      </div>
    </Grid>
  );
}

export default ActivityEmailOffPage;
