import React, { useRef, useEffect } from "react";
import Slider from "react-slick";
import { CircularProgress } from "@material-ui/core";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import useStyles from "./styles";
import logo from "../../assets/img/logo-icon-sm.png";
import { CarouselItem } from "./carousel-item";

export const SelectRoutine = ({ routineList, userList, isLoading }) => {
  const classes = useStyles();

  const sliderRef = useRef(null);

  const sliderSettings = {
    centerMode: true,
    arrows: false,
    infinite: false,
    slidesToShow: 1,
    variableWidth: true,
    swipeToSlide: true,
    // dots: true,
  };

  useEffect(() => {
    return () => {
      document.removeEventListener("keydown", () => {});
      document.removeEventListener("wheel", () => {});
    };
  }, []);

  document.addEventListener("keydown", (e) => {
    if (e.key === "ArrowRight") {
      // eslint-disable-next-line no-unused-expressions
      sliderRef.current?.slickNext();
    } else if (e.key === "ArrowLeft") {
      // eslint-disable-next-line no-unused-expressions
      sliderRef.current?.slickPrev();
    }
  });

  document.addEventListener("wheel", (e) => {
    if (e.deltaX > 0) {
      // eslint-disable-next-line no-unused-expressions
      sliderRef.current?.slickNext();
    } else if (e.deltaX < 0) {
      // eslint-disable-next-line no-unused-expressions
      sliderRef.current?.slickPrev();
    }
  });

  return (
    <div className={classes.formContainerSelectRoutine}>
      <div className={classes.selectRoutineHeader}>
        <h3 className={classes.selectRoutineHeaderTitle}>Welcome to Dory, the high-performance mental wellness community.</h3>
        <h3 className={classes.selectRoutineHeaderTitle}>Select the high-perfomer who inspires you most.</h3>
      </div>
      {isLoading && <CircularProgress className={classes.loadingItem} size={60} />}
      <Slider {...sliderSettings} ref={sliderRef}>
        {Object.keys(routineList).map((item, index) => {
          const userIDList = Object.keys(userList);
          const userIDIndex = userIDList.findIndex((value) => value === routineList[item].userId);
          if (userIDIndex !== -1) {
            const routineUserID = userIDList[userIDIndex];
            return (
              <CarouselItem
                key={index}
                id={index}
                routine={routineList[item]}
                routineUserID={routineUserID}
                user={userList[routineUserID]}
              />
            );
          }
          return "";
        })}
      </Slider>
      <div className={classes.mobileLogo}>
        <img src={logo} alt="logo" className={classes.logotypeImage} />
      </div>
    </div>
  );
};
