import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "@material-ui/styles";
import { CssBaseline } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import firebase from "firebase/app";
import { ReactReduxFirebaseProvider } from "react-redux-firebase";
import { createFirestoreInstance } from "redux-firestore";
import { Provider } from "react-redux";

import Themes from "./themes";
import App from "./components/App";
import * as serviceWorker from "./serviceWorker";
import { LayoutProvider } from "./context/LayoutContext";
import { UserProvider } from "./context/UserContext";
import FirestoreContext from "./context/firestore";
import DateFnsUtils from "@date-io/date-fns";

import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/functions";
import { firebaseConfig_dev } from "./constants/firebase";
import initializeStore from "./store";
import { NotificationContainer } from "react-notifications";

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig_dev);
  firebase.storage();
}

const firestore = firebase.firestore();

const rrfConfig = {
  userProfile: "users",
  useFirestoreForProfile: true,
  enableLogging: true,
};

const store = initializeStore();

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance,
};

ReactDOM.render(
  <Provider store={store}>
    <ReactReduxFirebaseProvider {...rrfProps}>
      <FirestoreContext.Provider value={firestore}>
        <LayoutProvider>
          <UserProvider>
            <ThemeProvider theme={Themes.default}>
              <NotificationContainer />
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <>
                  <CssBaseline />
                  <App />
                </>
              </MuiPickersUtilsProvider>
            </ThemeProvider>
          </UserProvider>
        </LayoutProvider>
      </FirestoreContext.Provider>
    </ReactReduxFirebaseProvider>
  </Provider>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
