import React from "react";
import useStyles from "./styles";
import { getNameAvatar } from "../../service/utils";
import { Button, Typography } from "@material-ui/core";
import { Credential } from "./credential";
import { useHistory } from "react-router-dom";
import { StyleAvatar } from "../../components/Avatar";

export const CarouselItem = ({ routine, user, routineUserID }) => {
  const classes = useStyles();
  const history = useHistory();

  const onPressSelectRoutine = () => {
    const searchParam = new URLSearchParams(history.location.search);
    searchParam.set("routine", routineUserID);
    history.push(`/create?${searchParam.toString()}`);
  };

  return (
    <div className={classes.carouselItemContainer}>
      <div className={classes.carouselItemAvatar}>
        <StyleAvatar uid={routineUserID} src={user.profilePhoto} string={getNameAvatar(user.firstname, user.lastname)} />
      </div>
      <Typography component="h3" className={classes.routineProfileName}>
        {user.firstname} {user.lastname}
      </Typography>
      <div className={classes.credentialContainer}>
        {routine?.credentials?.map((item, index) => (
          <Credential credential={item} key={index} />
        ))}
      </div>
      <Typography className={classes.routineStatement}>{routine.statement}</Typography>
      <Button size="large" variant="contained" fullWidth className={classes.selectRoutineButton} onClick={onPressSelectRoutine}>
        Select {user.firstname}
      </Button>
    </div>
  );
};
