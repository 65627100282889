import React, { useState, useCallback, useMemo, useEffect, useContext } from "react";
import { Grid, CircularProgress, Typography, Button, TextField, Fade, Link } from "@material-ui/core";
import useStyles from "./styles";
import logo from "../../assets/img/logo-icon.png";
import { useUserDispatch, registerUser } from "../../context/UserContext";

import useFirebase from "../../hooks/useFirebase";
import useFirestore from "../../hooks/useFirestore";
import { useHistory } from "react-router-dom";
import { validateEmail } from "../../helpers/util";
import { PRIVACY_URL, TERMS_URL } from "../../constants/consts";
import { SelectRoutine } from "./select-routine";
import { Credential } from "./credential";
import { getNameAvatar } from "../../service/utils";
import RoutineUserContext from "../../context/RoutineUserContext";
import { StyleAvatar } from "../../components/Avatar";

export const SignUp = () => {
  var classes = useStyles();
  const history = useHistory();
  const firebase = useFirebase();
  const firestore = useFirestore();
  // global
  var userDispatch = useUserDispatch();
  const { setCurrentRoutine, setCurrentRoutineUser, setCreatedUserName } = useContext(RoutineUserContext);

  // local
  var [isLoading, setIsLoading] = useState(false);
  var [error, setError] = useState({});
  var [formData, setFormData] = useState({ firstLastName: "", email: "", password: "" });

  const [routines, setRoutines] = useState({});
  const [users, setUsers] = useState({});

  const [selectedRoutine, setSelectedRoutine] = useState({});
  const [selectedRoutineUser, setSelectedRoutineUser] = useState({});

  const [userLoading, setUserLoading] = useState(false);
  const [routineLoading, setRoutineLoading] = useState(false);

  const validation = useCallback(() => {
    setError({});
    const formErrors = {};
    if (!formData.firstLastName) {
      formErrors.firstLastName = "This field is required.";
    } else {
      const nameList = formData.firstLastName.trim().split(" ");
      if (nameList.length < 2) {
        formErrors.firstLastName = "First name and Last name is required.";
      }
    }
    if (!formData.email) {
      formErrors.email = "This field is required.";
    } else {
      if (!validateEmail(formData.email)) {
        formErrors.email = "Invalid email.";
      }
    }
    if (!formData.password) {
      formErrors.password = "This field is required";
    } else {
      if (formData.password.length < 6) {
        formErrors.password = "Password should be at least 6 characters";
      }
    }

    if (Object.keys(formErrors).length > 0) {
      setError(formErrors);
      return false;
    }

    return true;
  }, [formData]);

  const handleSignup = useCallback(async () => {
    if (!validation()) {
      return;
    }

    const urlParams = new URLSearchParams(history.location.search);
    const partner = urlParams.get("partner") || "";
    const legacy = urlParams.get("legacy") || false;
    const referUserID = urlParams.get("ref") || "";

    const nameList = formData.firstLastName.trim().split(" ");
    const firstName = nameList.slice(0, -1).join(" ");
    const lastName = nameList.slice(-1)[0];
    const selectedRoutineID = urlParams.get("routine");

    setCreatedUserName(firstName);

    await registerUser(
      firebase,
      firestore,
      userDispatch,
      firstName,
      lastName,
      formData.email,
      formData.password,
      partner,
      legacy,
      referUserID,
      history,
      setIsLoading,
      setError,
      selectedRoutineID,
    );
  }, [firebase, firestore, formData, history, userDispatch, validation, setCreatedUserName]);

  const getUsers = useCallback(() => {
    if (!firestore) return;
    setUserLoading(true);
    firestore
      .collection("users")
      .get()
      .then((querySnapshot) => {
        let userList = {};
        setUserLoading(false);
        querySnapshot.forEach((userDoc) => {
          userList[userDoc.id] = userDoc.data();
        });
        setUsers(userList);
      });
  }, [firestore]);

  const getRoutines = useCallback(() => {
    if (!firestore) return;
    setRoutineLoading(true);
    firestore
      .collection("routines")
      .where("active", "==", true)
      .get()
      .then((querySnapshot) => {
        const routineList = {};
        setRoutineLoading(false);
        querySnapshot.forEach((routineDoc) => {
          const routine = routineDoc.data();
          routineList[routine.userId] = routineDoc.data();
        });
        setRoutines(routineList);
      });
  }, [firestore]);

  const ableSignup = useMemo(() => {
    if (!formData.firstLastName || !formData.email || !formData.password) {
      return false;
    }
    return true;
  }, [formData]);

  const urlParams = useMemo(() => {
    return new URLSearchParams(history.location.search);
  }, [history.location.search]);

  useEffect(() => {
    if (ableSignup) {
      validation();
    }
  }, [ableSignup, validation]);

  useEffect(() => {
    getRoutines();
    getUsers();
  }, [firestore, getRoutines, getUsers]);

  useEffect(() => {
    if (!urlParams || !Object.keys(routines).length || !Object.keys(users).length) return;

    if (urlParams.get("routine")) {
      const selectedRoutineUserID = urlParams.get("routine");
      const selectedRoutine = routines[selectedRoutineUserID];
      if (!selectedRoutine) {
        history.push("/create");
      } else {
        const selectedUserID = selectedRoutine.userId;
        const userIDList = Object.keys(users);
        const selectedRoutineUserIndex = userIDList.findIndex((item) => item === selectedUserID);
        if (selectedRoutineUserIndex === -1) {
          history.push("/create");
        } else {
          const selectedUser = users[selectedUserID];
          setSelectedRoutine(selectedRoutine);
          setSelectedRoutineUser(selectedUser);
          setCurrentRoutine(selectedRoutine);
          setCurrentRoutineUser(selectedUser);
        }
      }
    }
  }, [urlParams, routines, users, history, setCurrentRoutine, setCurrentRoutineUser]);

  const handleFormData = useCallback(
    (key) => (e) => {
      setFormData({ ...formData, [key]: e.target.value });
    },
    [formData],
  );

  const onPressDifferentRoutine = () => {
    const searchParam = new URLSearchParams(history.location.search);
    searchParam.delete("routine");
    history.push(`/create?${searchParam.toString()}`);
  };

  return (
    <Grid container className={classes.container}>
      <div className={classes.logotypeContainer}>
        <img src={logo} alt="logo" className={classes.logotypeImage} />
      </div>
      {!urlParams.get("routine") && <SelectRoutine routineList={routines} userList={users} isLoading={userLoading || routineLoading} />}
      {!!urlParams.get("routine") && (
        <div className={classes.formContainer}>
          <div className={classes.form}>
            <div className={classes.headerTitleContainer}>
              <Typography component="h1" className={classes.headerTitle}>
                Are you ready to join my high-performance routine?
              </Typography>
              <Link underline="always" className={classes.selectAnotherRoutine} component="button" onClick={onPressDifferentRoutine}>
                Select a different high-performer
              </Link>
            </div>
            <div className={classes.routineProfileContainer}>
              <div className={classes.selectedUserAvatar}>
                <StyleAvatar
                  fontSize="30px"
                  uid={selectedRoutine?.userId}
                  src={selectedRoutineUser.profilePhoto}
                  string={getNameAvatar(selectedRoutineUser.firstname, selectedRoutineUser.lastname)}
                />
              </div>
              <Typography component="h3" className={classes.routineProfileName}>
                {selectedRoutineUser.firstname} {selectedRoutineUser.lastname}
              </Typography>
              {!!selectedRoutine?.credentials?.length && <Credential credential={selectedRoutine.credentials[0]} />}
            </div>
            <TextField
              id="first-last-name"
              InputProps={{ disableUnderline: true, classes: { root: classes.inputRoot } }}
              variant="filled"
              value={formData.firstLastName}
              onChange={handleFormData("firstLastName")}
              margin="dense"
              placeholder="First and Last name"
              fullWidth
              error={!!error.firstLastName}
              helperText={error.firstLastName}
              autoComplete="off"
            />
            <TextField
              id="email"
              InputProps={{
                disableUnderline: true,
                classes: {
                  root: classes.inputRoot,
                },
              }}
              variant="filled"
              value={formData.email}
              onChange={handleFormData("email")}
              margin="dense"
              placeholder="Email address"
              type="email"
              fullWidth
              error={!!error.email}
              helperText={error.email}
              autoComplete="off"
            />
            <TextField
              id="password"
              InputProps={{
                disableUnderline: true,
                classes: {
                  root: classes.inputRoot,
                },
              }}
              variant="filled"
              value={formData.password}
              onChange={handleFormData("password")}
              margin="dense"
              placeholder="Password"
              type="password"
              fullWidth
              error={!!error.password}
              helperText={error.password}
              autoComplete="off"
            />
            <Fade in={!!error.all}>
              <Typography color="secondary" className={classes.errorMessage}>
                {error.all}
              </Typography>
            </Fade>
            <div className={classes.creatingButtonContainer}>
              {isLoading ? (
                <CircularProgress size={26} />
              ) : (
                <Button
                  size="large"
                  variant="contained"
                  fullWidth
                  className={classes.createAccountButton}
                  onClick={handleSignup}
                  disabled={!ableSignup}
                >
                  GET STARTED
                </Button>
              )}
            </div>
            <Typography color="primary" className={classes.copyright}>
              By signing up, I accept the{" "}
              <a target="_blank" rel="noopener noreferrer" href={TERMS_URL}>
                Terms of Service
              </a>{" "}
              and{" "}
              <a target="_blank" rel="noopener noreferrer" href={PRIVACY_URL}>
                Privacy Policy
              </a>
            </Typography>
          </div>
          <div className={classes.mobileLogo}>
            <img src={logo} alt="logo" className={classes.logotypeImage} />
          </div>
        </div>
      )}
    </Grid>
  );
};
