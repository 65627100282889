export const getNameAvatar = (firstName, lastName) => {
  if (!firstName || !lastName) return "";

  return firstName.slice(0, 2);
};

export function getColorPalette(uid, maxColors) {
  let codeSum = 0;
  for (let i = 0; i < uid?.length; i++) {
    codeSum += uid.charCodeAt(i);
  }

  const index = codeSum % maxColors;

  return index;
}
