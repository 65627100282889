import { defineAction } from "redux-define";

const NAMESPACE = "";

export default {
  DRAWER: defineAction("DRAWER", ["TOGGLE"], NAMESPACE),
  CONTRACT: defineAction(
    "CONTRACT",
    ["SET", "CHANGE"],
    NAMESPACE
  ),
  BILL: defineAction(
    "BILL",
    ["SET"],
    NAMESPACE
  ),
};
