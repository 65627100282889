import React, { useState, useEffect, useCallback } from "react";
import { Drawer, IconButton, List } from "@material-ui/core";
import {
  // Home as HomeIcon,
  // NotificationsNone as NotificationsIcon,
  // FormatSize as TypographyIcon,
  // FilterNone as UIElementsIcon,
  // BorderAll as TableIcon,
  // QuestionAnswer as SupportIcon,
  // LibraryBooks as LibraryIcon,
  // HelpOutline as FAQIcon,
  ArrowBack as ArrowBackIcon,
  Person,
  // People,
  ListAlt,
  Assignment,
} from "@material-ui/icons";
import { useTheme } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import classNames from "classnames";

// styles
import useStyles from "./styles";

// components
import SidebarLink from "./components/SidebarLink/SidebarLink";
// import Dot from "./components/Dot";

// context
import { useLayoutState, useLayoutDispatch, toggleSidebar } from "../../context/LayoutContext";

const structure = [
  { id: 0, label: "Profile", link: "/profile", icon: <Person /> },
  {
    id: 1,
    label: "Make an Entry",
    link: "/set-journal",
    icon: <ListAlt />,
  },
  {
    id: 2,
    label: "My Scorecard",
    link: "/my-journal",
    icon: <Assignment />,
  },
  // {
  //   id: 3,
  //   label: "Group Journals",
  //   link: "/group-journal",
  //   icon: <People />,
  // },
];

function Sidebar({ location }) {
  var classes = useStyles();
  var theme = useTheme();

  // global
  var { isSidebarOpened } = useLayoutState();
  var layoutDispatch = useLayoutDispatch();

  // local
  var [isPermanent, setPermanent] = useState(true);

  useEffect(function () {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });

  const handleListItemClick = useCallback(() => {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;
    if (isSmallScreen) {
      toggleSidebar(layoutDispatch);
    }
  }, [layoutDispatch, theme]);

  return (
    <Drawer
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div>
      <List className={classes.sidebarList}>
        {structure.map((link) => (
          <SidebarLink key={link.id} location={location} isSidebarOpened={isSidebarOpened} {...link} onClick={handleListItemClick} />
        ))}
      </List>
    </Drawer>
  );

  // ##################################################################
  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
      layoutDispatch({ type: "Hide_SIDEBAR" });
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
      layoutDispatch({ type: "SHOW_SIDEBAR" });
    }
  }
}

export default withRouter(Sidebar);
