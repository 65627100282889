import React from "react";
import { Route, Switch, Redirect, BrowserRouter } from "react-router-dom";

// components
import Layout from "./Layout";

// pages
import Error from "../pages/error";
import ReminderOffPage from "../pages/reminderOff";
import ActivityEmailOffPage from "../pages/activityEmailOff";

// context
import { useUserState } from "../context/UserContext";
import DialogProvider from "../providers/DialogProvider";
import { SignUp } from "../pages/signup";
import { AppDownLoadPage } from "../pages/appDownLoadPage";
import RoutineUserProvider from "../providers/RoutineUserProvider";
import { RoutineRedirect } from "./RoutineRedirect";

export default function App() {
  // global
  var { isAuthenticated } = useUserState();

  return (
    <BrowserRouter>
      <DialogProvider>
        <RoutineUserProvider>
          <Switch>
            <Route exact path="/apps" component={AppDownLoadPage} />
            <PublicRoute path="/create" component={SignUp} />
            <PublicRoute path="/reminder-off" component={ReminderOffPage} />
            <PublicRoute path="/activity-email-off" component={ActivityEmailOffPage} />
            <Route path="/ref/:id" component={RoutineRedirect} />
            <PrivateRoute path="/" component={Layout} />
            <Route component={Error} />
          </Switch>
        </RoutineUserProvider>
      </DialogProvider>
    </BrowserRouter>
  );

  // #######################################################################

  function PrivateRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated ? (
            <Redirect
              to={{
                pathname: "/apps",
                state: {
                  from: props.location,
                },
              }}
            />
          ) : (
            <Redirect
              to={{
                pathname: "/create",
                state: {
                  from: props.location,
                },
              }}
            />
          )
        }
      />
    );
  }

  function PublicRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated ? (
            <Redirect
              to={{
                pathname: "/apps",
              }}
            />
          ) : (
            React.createElement(component, props)
          )
        }
      />
    );
  }
}
